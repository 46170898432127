@font-face {
  font-family: 'Helvetica';
  font-weight: normal;
  font-style: normal;
  src: url('./Helvetica.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  font-weight: bold;
  font-style: normal;
  src: url('./Helvetica-Bold.ttf') format('ttf');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  font-weight: normal;
  font-style: normal;
  src: url('./HelveticaNeue-45.otf') format('otf');
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  font-weight: bold;
  font-style: normal;
  src: url('./HelveticaNeue-65.otf') format('otf');
  font-display: swap;
}
